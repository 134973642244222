// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Home3 } from 'iconsax-react';

// icons
const icons = {
  dashboard: Home3
};

const navigation = {
  id: 'Dashboard',
  title: <FormattedMessage id="Dashboard" />,
  type: 'item',
  url: '/dashboard',
  icon: icons.dashboard
};

export default navigation;
