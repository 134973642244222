import { lazy } from "react";

// project-imports
import GuestGuard from "utils/route-guard/GuestGuard";
import CommonLayout from "layout/CommonLayout";
import Loadable from "components/Loadable";
import RSetPass from "../pages/auth/auth1/ResetPassword";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/auth/auth1/login")));
const AuthRegister = Loadable(lazy(() => import("pages/auth/auth1/register")));
const AuthForgotPassword = Loadable(
  lazy(() => import("pages/auth/auth1/forgot-password"))
);
const AuthCheckMail = Loadable(
  lazy(() => import("pages/auth/auth1/check-mail"))
);

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: "/",
          element: <AuthLogin />,
        },
        {
          path: "login",
          element: <AuthLogin />,
        },
        {
          path: "register",
          element: <AuthRegister />,
        },
        {
          path: "forgot-password",
          element: <AuthForgotPassword />,
        },
        {
          path: "check-mail",
          element: <AuthCheckMail />,
        },
        {
          path: "resetPassword",
          element: <RSetPass />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
