import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonBase } from '@mui/material';
import mavenLogo from '../../assets/images/logo.png'
import withRouter from 'components/withRouter';

const LogoSection = (props) => {
  const { sx, to } = props, { APP_DEFAULT_PATH } = props?.config;
  <ButtonBase ButtonBase disableRipple component={Link} to={!to ? APP_DEFAULT_PATH : to} sx={sx} >
    <img
      src={mavenLogo}
      alt="Logo"
      style={{ maxWidth: '100%', maxHeight: '100%', width: 'auto', height: 'auto' }}
    />
  </ButtonBase>
};
LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default withRouter(LogoSection);
