import { Grid } from "@mui/material";
import { Award, Profile2User } from "iconsax-react";
import EcommerceMetrix from "components/EcommerceMetrix";
import MainCard from "components/MainCard";
import withRouter from "components/withRouter";

const Dashboard = (props) => {
  const { theme } = props, { navigate } = props?.router;
  const handleClick = async (itm) => {
    navigate(itm);
  };

  return (
    <MainCard title="Dashboard">
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          lg={4}
          sm={6}
          onClick={(e) => handleClick("/client/client-list")}
        >
          <EcommerceMetrix
            secondary="Clients"
            content="Total Client 5"
            color={theme.palette.primary.main}
            iconPrimary={Profile2User}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sm={6}
          onClick={(e) => handleClick("/standards/standard-list")}
        >
          <EcommerceMetrix
            secondary="Standards"
            content="Total Client 5"
            color={theme.palette.warning.main}
            iconPrimary={Award}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default withRouter(Dashboard);
