import { FormattedMessage } from "react-intl";

import {
  Home3,
  Profile2User,
  Box1,
  DocumentForward,
  Category2,
  DocumentCode2,
  MessageQuestion,
  Award,
  DocumentCode
} from "iconsax-react";
import contentsa from "./contents"
import navigation from './navigation';

const icons = {
  dashboard: Home3,
  client: Profile2User,
  product: Box1,
  templates: DocumentForward,
  category: Category2,
  question: MessageQuestion,
  standard: Award,
  documentization: DocumentCode,
};

const Clients = {
  id: "Clients",
  title: <FormattedMessage id="Clients" />,
  type: "item",
  url: "/client/client-list",
  icon: icons.client,
};

const Standards = {
  id: "Standards",
  title: <FormattedMessage id="Standards" />,
  type: "item",
  url: "/standards/standard-list",
  icon: icons.standard,
};

// const contents = {
//   id: "Document Question",
//   title: <FormattedMessage id="contents" />,
//   type: "group",
//   icon: icons.question,
//   children: [
//     {
//       id: "Document Question",
//       title: <FormattedMessage id="Document Question" />,
//       type: "collapse",
//       icon: icons.question,
//       children: [
//         {
//           id: "allQuestions",
//           title: <FormattedMessage id="Question List" />,
//           type: "item",
//           url: "/documentQuestion/allQuestions",
//         },
//         {
//           id: "short-question",
//           title: <FormattedMessage id="Short Question" />,
//           type: "item",
//           url: "/documentQuestion/short-question",
//         },
//         {
//           id: "mcq-question",
//           title: <FormattedMessage id="MCQ Question" />,
//           type: "item",
//           url: "/documentQuestion/mcq-question",
//         },
//         {
//           id: "mcmc-question",
//           title: <FormattedMessage id="MCMC Question" />,
//           type: "item",
//           url: "/documentQuestion/mcmc-question",
//         },
//         {
//           id: "dropdown-question",
//           title: <FormattedMessage id="Dropdown Question" />,
//           type: "item",
//           url: "/documentQuestion/dropdown-question",
//         },
//       ],
//     },
//   ],
// };

const menuItems = {
  items: [navigation, Clients, Standards, contentsa]
};

export default menuItems;
