import { Tooltip } from "@mui/material";
import withRouter from "components/withRouter";

const CustomTooltip = (props) => {
  const { title, children, onClick, theme, placement } = props;
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.grey[50]
                : theme.palette.grey[700],
            opacity: 0.9,
          },
        },
      }}
      title={title}
      placement={placement}
    >
      <span onClick={onClick}>{children}</span>
    </Tooltip>
  );
};

export default withRouter(CustomTooltip);
