import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Constants,
} from "services/keyConstants";
import { ApiServices } from "services/apiServices";
import * as url from "services/url_helper";
import { useTheme } from "@mui/material";
import service from "services/constant";
import {
  ThemeMode,
  DRAWER_WIDTH,
  MenuOrientation,
  MINI_DRAWER_WIDTH,
  APP_DEFAULT_PATH,
  ThemeDirection,
  HEADER_HEIGHT,
  HORIZONTAL_MAX_ITEM
} from "config";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react";
import { getDocumentTitle } from "services/utils";
import { renderFilterTypes, GlobalFilter } from "utils/react-table";

const withRouter = (Component) => (props) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { onChangeDownloadState } = useContext(ConfigContext);
  const getPageTitle = (title) => {
    document.title = getDocumentTitle(title);
  };

  return (
    <Component
      {...props}
      getTitle={getPageTitle}
      theme={theme}
      router={{ location, navigate, params }}
      ApiServices={ApiServices}
      commonUrl={url}
      service={service}
      Constants={Constants}
      onChangeDownloadState={onChangeDownloadState}
      reactTable={{
        renderFilterTypes,
        GlobalFilter
      }}
      config={{
        ThemeMode,
        DRAWER_WIDTH,
        MenuOrientation,
        MINI_DRAWER_WIDTH,
        APP_DEFAULT_PATH,
        ThemeDirection,
        HEADER_HEIGHT,
        HORIZONTAL_MAX_ITEM
      }}
    />
  );
};

export default withRouter;
