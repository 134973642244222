import PropTypes from "prop-types";
import { Typography, Skeleton, CircularProgress } from "@mui/material";
import { useState } from "react";
import CustomAccordion from "./CustomAccordian";

const CustomAccordionSkeleton = ({ isLoading = true }) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordions = Array.from({ length: 10 }, (_, index) => ({
    key: `panel${index + 1}`,
    summary: `Accordion ${index + 1}`,
    details: `Details for Accordion ${index + 1}`,
  }));

  return (
    <>
      {accordions?.map((accordion) => (
        <CustomAccordion
          key={accordion?.key}
          disabled={isLoading}
          expanded={expanded === accordion?.key}
          onChange={handleAccordionChange(accordion?.key)}
          summary={
            <Typography variant="h6" component="div">
              <Skeleton
                variant="rectangular"
                height={15}
                width="100px"
                sx={{ borderRadius: "12px" }}
              />
            </Typography>
          }
          details={<CircularProgress />}
        />
      ))}
    </>
  );
};

CustomAccordionSkeleton.propTypes = {
  expandIcon: PropTypes.node,
  accordionProps: PropTypes.object,
  summaryProps: PropTypes.object,
  detailsProps: PropTypes.object,
};

export default CustomAccordionSkeleton;
