import { cloneElement } from 'react';
import { alpha } from '@mui/material/styles';
import { AppBar, Box, Container, useScrollTrigger } from '@mui/material';
import Navigation from './DrawerContent/Navigation';
import { HEADER_HEIGHT } from 'config';
import useConfig from 'hooks/useConfig';
import withRouter from 'components/withRouter';

function ElevationScroll({ children, window, theme }) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window
  });

  theme.shadows[4] = theme.customShadows.z1;
  theme.shadows[1] = theme.customShadows.z2;

  return cloneElement(children, {
    elevation: trigger ? 4 : 1
  });
}

const CustomAppBar = (props) => {
  const { theme } = props;
  const { container } = useConfig();

  return (
    <ElevationScroll theme={theme}>
      <AppBar
        sx={{
          top: HEADER_HEIGHT,
          height: HEADER_HEIGHT,
          bgcolor: alpha(theme.palette.background.default, 0.8),
          backdropFilter: 'blur(8px)',
          width: '100%',
          justifyContent: 'center',
          borderTop: `1px solid ${theme.palette.divider}`,
          zIndex: 1098,
          color: theme.palette.secondary.main
        }}
      >
        <Container maxWidth={container ? 'xl' : false}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Navigation />
          </Box>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

export default withRouter(CustomAppBar);
