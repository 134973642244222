import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Dashboard from "pages/dashboard/dashboard";
import QuestionStandardList from "pages/apps/Standards/QuestionStandardList";

const AppClientList = Loadable(lazy(() => import("pages/apps/client/list")));
const StandardList = Loadable(
  lazy(() => import("pages/apps/Standards/StandardList"))
);
const StandardDetails = Loadable(
  lazy(() => import("pages/apps/Standards/StandardDetails"))
);

const ShortQuestion = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/ShortQuestion"))
);
const McqQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/McqQuestions"))
);
const McmcQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/McmcQuestions"))
);
const QuestionList = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/QuestionList"))
);
const DropdownQuestions = Loadable(
  lazy(() => import("pages/apps/DocumentQuestion/DropdownQuestions"))
);

const createRoute = (path, element, children) => ({ path, element, children });

// ==============================|| MAIN ROUTES ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    createRoute(
      "/",
      <AuthGuard>
        <MainLayout />
      </AuthGuard>,
      [
        createRoute("dashboard", <Dashboard />),
        createRoute("client", null, [
          createRoute("client-list", <AppClientList />),
        ]),
        createRoute("standards", null, [
          createRoute("standard-list", <StandardList />),
          createRoute("standard-details", <StandardDetails />),
          createRoute("questions", <QuestionStandardList />),
        ]),
        createRoute("documentQuestion", null, [
          createRoute("mcq-question", <McqQuestions />),
          createRoute("mcmc-question", <McmcQuestions />),
          createRoute("short-question", <ShortQuestion />),
          createRoute("allQuestions", <QuestionList />),
          createRoute("dropdown-question", <DropdownQuestions />),
        ]),
      ],
    )],
};

export default MainRoutes;
