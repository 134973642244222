// material-ui
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import withRouter from "components/withRouter";

const RSetPass = (props) => {
  const { ApiServices, commonUrl } = props, { navigate, location } = props?.router;
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const query = new URLSearchParams(location.search);
  const param = query.get("id");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === rePassword) {
      const response = await ApiServices.callServicePutWithBodyData(
        commonUrl?.RESET_PASSWORD + param,
        { password }
      );
      if (response !== undefined) {
        navigate("/login");
      }
    } else {
      console.error("error");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Box
        component={"form"}
        onSubmit={handleSubmit}
        sx={{
          width: 300,
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: "background.paper",
        }}
      >
        <Stack spacing={2}>
          <Stack spacing={1}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <TextField
              id="password"
              type="password"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Enter Password"
              fullWidth
            />
          </Stack>
          <Stack spacing={1}>
            <InputLabel htmlFor="retype-password">Retype Password</InputLabel>
            <TextField
              id="retype-password"
              type="password"
              onChange={(e) => {
                setRePassword(e.target.value);
              }}
              placeholder="Retype Password"
              fullWidth
            />
          </Stack>

          <Button type="submit" variant="contained" fullWidth>
            Reset Password
          </Button>
        </Stack>
      </Box>
    </Grid>
  );
}

export default withRouter(RSetPass); 