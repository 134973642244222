import { FormattedMessage } from "react-intl";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChatIcon from '@mui/icons-material/Chat';
import SmsIcon from '@mui/icons-material/Sms';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  Home3,
  Profile2User,
  Box1,
  DocumentForward,
  Category2,
  MessageQuestion,
  Award,
  DocumentCode
} from "iconsax-react";

const icons = {
  dashboard: Home3,
  client: Profile2User,
  product: Box1,
  SmsIcon: SmsIcon,
  templates: DocumentForward,
  category: Category2,
  question: MessageQuestion,
  standard: Award,
  SmsFailedIcon: SmsFailedIcon,
  documentization: DocumentCode,
  ListAltIcon: ListAltIcon,
  ChatIcon: ChatIcon,
  QuestionAnswerIcon: QuestionAnswerIcon,
};

const contents = {
  id: "DocumentQuestion",
  title: <FormattedMessage id="Document Question" />,
  type: "group",
  icon: icons.question,
  children: [
    {
      id: "allQuestions",
      title: <FormattedMessage id="Question List" />,
      type: "item",
      icon: icons.ListAltIcon,
      url: "/documentQuestion/allQuestions",
    },
    {
      id: "short-question",
      title: <FormattedMessage id="Short Question" />,
      type: "item",
      icon: icons.QuestionAnswerIcon,
      url: "/documentQuestion/short-question",
    },
    {
      id: "mcq-question",
      title: <FormattedMessage id="MCQ Question" />,
      type: "item",
      icon: icons.ChatIcon,
      url: "/documentQuestion/mcq-question",
    },
    {
      id: "mcmc-question",
      title: <FormattedMessage id="MCMC Question" />,
      type: "item",
      icon: icons.SmsIcon,
      url: "/documentQuestion/mcmc-question",
    },
    {
      id: "dropdown-question",
      title: <FormattedMessage id="Dropdown Question" />,
      type: "item",
      icon: icons.SmsFailedIcon,
      url: "/documentQuestion/dropdown-question",
    },
  ],
};

export default contents;
